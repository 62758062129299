import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ToasterService } from 'app/main/components/toaster.service';
import { LoginService } from 'app/services/login.service';

@Component({
    selector: 'fuse-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss']
})
export class FuseConfirmDialogComponent {
    public confirmMessage: string;
    public isTextField: boolean = false;
    public verifyCustomerData: any;
    public otp: any;
    public userStatus: any;
    public customerStatusId: any;
    /**
     * Constructor
     *
     * @param {MatDialogRef<FuseConfirmDialogComponent>} dialogRef
     */
    constructor(
        public dialogRef: MatDialogRef<FuseConfirmDialogComponent>,
        private loginService: LoginService,
        private toaster: ToasterService,
    ) {
        this.loginService.CustomerStatusMaster().subscribe((res: any) => {
            // this.customerStatusId = this.verifyCustomerData.customerStatusID;
            this.userStatus = res;
        })

    }

    handleInput(event: any) {
        if (!isNaN(event.target.value)) {
            event.target.value = event.target.value;
            this.otp = event.target.value;
        } else {
            event.target.value = '';
            this.otp = event.target.value
        }
    }

    onSelectChange(value) {
        this.customerStatusId = value;
    }

    verifyCustomer() {

        if (!this.customerStatusId) {
            this.toaster.show('error', 'Error', "Select status");
            return;
        }   
        debugger
        // this.loginService.verifyCustomer({
        //     "otp": this.otp,
        //     "email": this.verifyCustomerData?.customerEmail,
        //     "customerFirstName": this.verifyCustomerData?.customerFirstName,
        //     "customerLastName": this.verifyCustomerData?.customerLastName,
        //     "customerId": this.verifyCustomerData?.customerId,
        //     "customerStatusId": this.customerStatusId
        // }).subscribe((res: any) => {
        //     if (!res?.errors) {
        //         this.dialogRef.close(true)
        //         this.toaster.show('success', 'Message', "Verification successful");
        //         window.location.reload()
        //     } else {
        //         this.toaster.show('error', 'Error', res?.errors || "Something went wrong");
        //     }
        // })
    }
}
