<div *ngIf="this.loader.getLoading()" class="cssload-container logo-Loader">
    <!-- <img  width="128" src="assets/images/logos/D_logo.svg">
    <div class="cssload-speeding-wheel"> -->
    <!-- </div> -->
    <div id="loader">
        <div class="loader_center">
            <div class="cube-loader">
                <div class="cube-top"></div>
                <div class="cube-wrapper">
                    <span class="cube-span" style="--i:0">
                        <div class="taco-container"><img src="../../../../assets/images/favicon.png" alt=""></div>
                    </span>
                    <span class="cube-span" style="--i:1">
                        <div class="taco-container"><img src="../../../../assets/images/favicon.png" alt=""></div>
                    </span>
                    <span class="cube-span" style="--i:2">
                        <div class="taco-container"><img src="../../../../assets/images/favicon.png" alt=""></div>
                    </span>
                    <span class="cube-span" style="--i:3">
                        <div class="taco-container"><img src="../../../../assets/images/favicon.png" alt=""></div>
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>