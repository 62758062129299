import { HttpClient, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { map } from "rxjs/operators";
import { LoaderService } from "./loader.service";

@Injectable({
    providedIn: "root",
})
export class LoginService {
    constructor(private http: HttpClient, private loadingService: LoaderService) {}

    loginUser(payload) {
        this.loadingService.setLoading(true);
        return this.http
            .post(environment.apiEndpoint + "Account/Authenticate", payload)
            .pipe(
                map((res: any) => {
                    if (res.status === 200) {
                        localStorage.setItem(
                            "user",
                            JSON.stringify(res.payload)
                        );
                    }
                    this.loadingService.setLoading(false);
                    return res;
                })
            );
    }

    registerUser(payload) {
        this.loadingService.setLoading(true);
        return this.http
            .post(
                environment.apiEndpoint +
                    "OutsideCustomerRegistration/AddOrUpdateOutsideCustomerRegistration",
                payload
            )
            .pipe(
                map((res: any) => {
                    this.loadingService.setLoading(false);
                    return res;
                })
            );
    }

    requestOtp(payload) {
        this.loadingService.setLoading(true);
        return this.http
            .post(environment.apiEndpoint + "Account/otp", payload)
            .pipe(
                map((res: any) => {
                    this.loadingService.setLoading(false);
                    return res;
                })
            );
    }

    forgotPassword(payload) {
        this.loadingService.setLoading(true);
        return this.http
            .post(environment.apiEndpoint + "Account/ForgotPassword", payload)
            .pipe(
                map((res: any) => {
                    this.loadingService.setLoading(false);
                    return res;
                })
            );
    }

    verifyUser(payload) {
        this.loadingService.setLoading(true);
        return this.http
            .post(environment.apiEndpoint + "Account/verify", payload)
            .pipe(
                map((res: any) => {
                    this.loadingService.setLoading(false);
                    return res;
                })
            );
    }

    verifyCustomer(payload) {
        this.loadingService.setLoading(true);
        return this.http
            .post(environment.apiEndpoint + "Account/VerifyCustomer", payload)
            .pipe(
                map((res: any) => {
                    this.loadingService.setLoading(false);
                    return res;
                })
            );
    }

    CustomerStatusMaster() {
        this.loadingService.setLoading(true);
        return this.http
            .get(
                environment.apiEndpoint +
                    "CustomerStatusMaster/CustomerStatusMaster"
            )
            .pipe(
                map((res: any) => {
                    this.loadingService.setLoading(false);
                    return res.payload;
                })
            );
    }

    StateMaster() {
        this.loadingService.setLoading(true);
        return this.http
            .get(
                environment.apiEndpoint +
                    "OutsideCustomerRegistration/GetOutSideStateTax"
            )
            .pipe(
                map((res: any) => {
                    this.loadingService.setLoading(false);
                    return res.payload;
                })
            );
    }

    ChangePassword(payload) {
        this.loadingService.setLoading(true);
        return this.http
            .post(environment.apiEndpoint + "Account/ResetPassword", payload)
            .pipe(
                map((res: any) => {
                    this.loadingService.setLoading(false);
                    return res;
                })
            );
    }

    GetRoleModulePermission(payload) {
        this.loadingService.setLoading(true);
        return this.http
            .get(
                environment.apiEndpoint +
                    `RolePermission/GetRolePermissions?RoleId=${payload}`
            )
            .pipe(
                map((res: any) => {
                    this.loadingService.setLoading(false);
                    return res;
                })
            );
    }

    UpdateRole(payload) {
        this.loadingService.setLoading(true);
        return this.http
            .post(environment.apiEndpoint + `RolePermission`, payload)
            .pipe(
                map((res: any) => {
                    this.loadingService.setLoading(false);
                    return res;
                })
            );
    }
}
