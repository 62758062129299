<h1 matDialogTitle>Confirm</h1>
<div mat-dialog-content>{{confirmMessage}}</div>
<div mat-dialog-actions class="pt-24">
    <!-- <mat-form-field> -->
    <div *ngIf="isTextField===true" class="mr-8">
        <label>Select User Status</label>
        <select (change)="onSelectChange($event.target.value)" required>
            <option value="" disabled>Select</option>
            <option *ngFor="let option of userStatus" [value]="option.customerStatusId"
                [attr.selected]="option.customerStatusId === this.customerStatusId ? 'selected' : null">
                {{ option.statusName }}
            </option>
        </select>
        <!-- <input placeholder="OTP" maxlength="6" (input)="handleInput($event)"> -->

    </div>

    <!-- </mat-form-field> -->

    <button *ngIf="isTextField===false" mat-raised-button class="mat-accent mr-16"
        (click)="dialogRef.close(true)">Confirm</button>
    <button *ngIf="isTextField===true" mat-raised-button class="mat-accent mr-16"
        (click)="verifyCustomer()">Verify</button>
    <button mat-button (click)="dialogRef.close(false)">Cancel</button>
</div>