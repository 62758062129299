import { FuseNavigation } from "@fuse/types";

export const navigation: FuseNavigation[] = [
    {
        id: "applications",
        title: "Applications",
        translate: "NAV.APPLICATIONS",
        type: "group",
        icon: "apps",
        children: [
            {
                id: "dashboards",
                title: "Dashboards",
                translate: "NAV.DASHBOARDS",
                type: "item",
                icon: "dashboard",
                url: "/apps/dashboards/project",
            },
            {
                id: "customerList",
                title: "Reseller",
                type: "item",
                icon: "dashboard",
                url: "/apps/master/customerList",
                isAdmin: true,
            },
            {
                id: "vendors",
                title: "Customer",
                type: "item",
                icon: "dashboard",
                url: "/apps/master/vendors",
                isAdmin: false,
            },
            {
                id: "master",
                title: "Door Info",
                translate: "Door Info",
                type: "collapsable",
                icon: "folder",
                isAdmin: true,
                children: [
                    {
                        id: "doorCategory",
                        title: "Door Category",
                        type: "item",
                        url: "/apps/master/doorCategory",
                        isAdmin: true,
                    },
                    {
                        id: "doorType",
                        title: "Door Type",
                        type: "item",
                        url: "/apps/master/doorType",
                        isAdmin: true,
                    },
                    {
                        id: "doorCompany",
                        title: "Door Company",
                        type: "item",
                        url: "/apps/master/doorCompany",
                        isAdmin: true,
                    },
                    {
                        id: "doorModel",
                        title: "Door Model",
                        type: "item",
                        url: "/apps/master/doorModel",
                        isAdmin: true,
                    },
                ].filter((item) => {
                    const getRole = localStorage.getItem("user");
                    const { roleName } = getRole ? JSON.parse(getRole) : "";
                    const isAdmin =
                        roleName === "SUPERADMIN" ? true : !item.isAdmin;
                    return item.isAdmin !== undefined ? isAdmin : true;
                }),
            },
            {
                id: "master",
                title: "Door Collection & Panel",
                translate: "Door Collection & Panel",
                type: "collapsable",
                icon: "folder",
                isAdmin: true,
                children: [
                    {
                        id: "doorCollection",
                        title: "Door Collection",
                        type: "item",
                        url: "/apps/master/doorCollection",
                        isAdmin: true,
                    },
                    {
                        id: "familyCollection",
                        title: "Family Collection",
                        type: "item",
                        url: "/apps/master/familyCollection",
                        isAdmin: true,
                    },
                    {
                        id: "doorPanel",
                        title: "Door Panel",
                        type: "item",
                        url: "/apps/master/doorPanel",
                        isAdmin: true,
                    },
                    {
                        id: "panelStyle",
                        title: "Panel Style",
                        type: "item",
                        url: "/apps/master/panelStyle",
                        isAdmin: true,
                    },
                    {
                        id: "modelPanel",
                        title: "Panel Width Specification",
                        type: "item",
                        url: "/apps/master/modelPanel",
                        isAdmin: true,
                    },
                ].filter((item) => {
                    const getRole = localStorage.getItem("user");
                    const { roleName } = getRole ? JSON.parse(getRole) : "";
                    const isAdmin =
                        roleName === "SUPERADMIN" ? true : !item.isAdmin;
                    return item.isAdmin !== undefined ? isAdmin : true;
                }),
            },
            {
                id: "master",
                title: "Door color & size",
                translate: "Door color & size",
                type: "collapsable",
                icon: "folder",
                isAdmin: true,
                children: [
                    {
                        id: "doorSizeHeight",
                        title: "Door Height",
                        type: "item",
                        url: "/apps/master/doorSizeHeight",
                        isAdmin: true,
                    },
                    {
                        id: "doorSizeWidth",
                        title: "Door Width",
                        type: "item",
                        url: "/apps/master/doorSizeWidth",
                        isAdmin: true,
                    },
                    {
                        id: "doorColor",
                        title: "Door Color",
                        type: "item",
                        url: "/apps/master/doorColor",
                        isAdmin: true,
                    },
                    {
                        id: "doorColorCombo",
                        title: "Color Combo",
                        type: "item",
                        url: "/apps/master/doorColorCombo",
                        isAdmin: true,
                    },
                    {
                        id: "modelColor",
                        title: "Model Color",
                        type: "item",
                        url: "/apps/master/modelColor",
                        isAdmin: true,
                    },
                    {
                        id: "modelWidth",
                        title: "Modal Width Configuration",
                        type: "item",
                        url: "/apps/master/modelWidth",
                        isAdmin: true,
                    },
                ].filter((item) => {
                    const getRole = localStorage.getItem("user");
                    const { roleName } = getRole ? JSON.parse(getRole) : "";
                    const isAdmin =
                        roleName === "SUPERADMIN" ? true : !item.isAdmin;
                    return item.isAdmin !== undefined ? isAdmin : true;
                }),
            },
            {
                id: "master",
                title: "Glazing",
                translate: "Glazing",
                type: "collapsable",
                icon: "folder",
                isAdmin: true,
                children: [
                    {
                        id: "glazingType",
                        title: "Glazing Type",
                        type: "item",
                        url: "/apps/master/glazingType",
                        isAdmin: true,
                    },
                    {
                        id: "glazingMaster",
                        title: "Glazing Master",
                        type: "item",
                        url: "/apps/master/doorGlazingMaster",
                        isAdmin: true,
                    },
                    {
                        id: "glazingModel",
                        title: "Glazing Model",
                        type: "item",
                        url: "/apps/master/glazingModel",
                        isAdmin: true,
                    },
                ].filter((item) => {
                    const getRole = localStorage.getItem("user");
                    const { roleName } = getRole ? JSON.parse(getRole) : "";
                    const isAdmin =
                        roleName === "SUPERADMIN" ? true : !item.isAdmin;
                    return item.isAdmin !== undefined ? isAdmin : true;
                }),
            },
            {
                id: "master",
                title: "Inserts",
                translate: "Inserts",
                type: "collapsable",
                icon: "folder",
                isAdmin: true,
                children: [
                    {
                        id: "insertsType",
                        title: "Inserts Type",
                        type: "item",
                        url: "/apps/master/insertsType",
                        isAdmin: true,
                    },
                    {
                        id: "insertsMaster",
                        title: "Inserts Master",
                        type: "item",
                        url: "/apps/master/insertsMaster",
                        isAdmin: true,
                    },
                    {
                        id: "insertsModel",
                        title: "Inserts Model",
                        type: "item",
                        url: "/apps/master/insertsModel",
                        isAdmin: true,
                    },
                ].filter((item) => {
                    const getRole = localStorage.getItem("user");
                    const { roleName } = getRole ? JSON.parse(getRole) : "";
                    const isAdmin =
                        roleName === "SUPERADMIN" ? true : !item.isAdmin;
                    return item.isAdmin !== undefined ? isAdmin : true;
                }),
            },
            {
                id: "master",
                title: "Spring",
                translate: "Spring",
                type: "collapsable",
                icon: "folder",
                isAdmin: true,
                children: [
                    {
                        id: "springCatType",
                        title: "Spring Category Type",
                        type: "item",
                        url: "/apps/master/springCategoryType",
                        isAdmin: true,
                    },
                    {
                        id: "springCategoery",
                        title: "Spring Categoery",
                        type: "item",
                        url: "/apps/master/springCategoery",
                        isAdmin: true,
                    },
                ].filter((item) => {
                    const getRole = localStorage.getItem("user");
                    const { roleName } = getRole ? JSON.parse(getRole) : "";
                    const isAdmin =
                        roleName === "SUPERADMIN" ? true : !item.isAdmin;
                    return item.isAdmin !== undefined ? isAdmin : true;
                }),
            },
            {
                id: "master",
                title: "Door Operator",
                translate: "Door Operator",
                type: "collapsable",
                icon: "folder",
                isAdmin: true,
                children: [
                    {
                        id: "doorOperatorType",
                        title: "Door Operator Type",
                        type: "item",
                        url: "/apps/master/doorOperatorType",
                        isAdmin: true,
                    },
                    {
                        id: "doorOperatorRail",
                        title: "Door Operator Rail",
                        type: "item",
                        url: "/apps/master/doorOperatorRail",
                    },
                    {
                        id: "doorOperator",
                        title: "Door Operator",
                        type: "item",
                        url: "/apps/master/doorOperator",
                        isAdmin: true,
                    },
                ].filter((item) => {
                    const getRole = localStorage.getItem("user");
                    const { roleName } = getRole ? JSON.parse(getRole) : "";
                    const isAdmin =
                        roleName === "SUPERADMIN" ? true : !item.isAdmin;
                    return item.isAdmin !== undefined ? isAdmin : true;
                }),
            },
            {
                id: "master",
                title: "Struts",
                translate: "Struts",
                type: "collapsable",
                icon: "folder",
                isAdmin: true,
                children: [
                    {
                        id: "sturtCategoeryType",
                        title: "Strut Category Type",
                        type: "item",
                        url: "/apps/master/sturtCategoeryType",
                        isAdmin: true,
                    },
                    {
                        id: "sturtCategoery",
                        title: "Strut Category",
                        type: "item",
                        url: "/apps/master/sturtCategoery",
                        isAdmin: true,
                    },
                    {
                        id: "strutHeightRange",
                        title: "Strut Height Range",
                        type: "item",
                        url: "/apps/master/strutHeightRange",
                        isAdmin: true,
                    },
                    {
                        id: "strutWidthRange",
                        title: "Strut Width Range",
                        type: "item",
                        url: "/apps/master/strutWidthRange",
                        isAdmin: true,
                    },
                ].filter((item) => {
                    const getRole = localStorage.getItem("user");
                    const { roleName } = getRole ? JSON.parse(getRole) : "";
                    const isAdmin =
                        roleName === "SUPERADMIN" ? true : !item.isAdmin;
                    return item.isAdmin !== undefined ? isAdmin : true;
                }),
            },
            {
                id: "master",
                title: "Tracks",
                translate: "Tracks",
                type: "collapsable",
                icon: "folder",
                isAdmin: true,
                children: [
                    {
                        id: "headRoom",
                        title: "HeadRoom",
                        type: "item",
                        url: "/apps/master/headRoom",
                        isAdmin: true,
                    },
                    {
                        id: "trackAdditionalSelection",
                        title: "Track Additional Selection",
                        type: "item",
                        url: "/apps/master/trackAdditionalSelection",
                        isAdmin: true,
                    },
                    {
                        id: "headroomAdditionalSelection",
                        title: "Headroom Additional Selection",
                        type: "item",
                        url: "/apps/master/headroomAdditionalSelection",
                        isAdmin: true,
                    },
                    {
                        id: "jambMount",
                        title: "Jamb Mount",
                        type: "item",
                        url: "/apps/master/jambMount",
                        isAdmin: true,
                    },
                    {
                        id: "jambMaterial",
                        title: "Jamb Material",
                        type: "item",
                        url: "/apps/master/jambMaterial",
                        isAdmin: true,
                    },
                    {
                        id: "jambMountMaterial",
                        title: "Jamb Mount Material",
                        type: "item",
                        url: "/apps/master/jambMountMaterial",
                        isAdmin: true,
                    },
                ].filter((item) => {
                    const getRole = localStorage.getItem("user");
                    const { roleName } = getRole ? JSON.parse(getRole) : "";
                    const isAdmin =
                        roleName === "SUPERADMIN" ? true : !item.isAdmin;
                    return item.isAdmin !== undefined ? isAdmin : true;
                }),
            },
            {
                id: "master",
                title: "Additional Hardware",
                translate: "Additional Hardware",
                type: "collapsable",
                icon: "folder",
                isAdmin: true,
                children: [
                    {
                        id: "sealMaster",
                        title: "Seal Master",
                        type: "item",
                        url: "/apps/master/sealMaster",
                        isAdmin: true,
                    },
                    {
                        id: "doorSealCategory",
                        title: "Door Seal Category",
                        type: "item",
                        url: "/apps/master/doorSealCategory",
                        isAdmin: true,
                    },
                    {
                        id: "doorLock",
                        title: "Door Lock",
                        type: "item",
                        url: "/apps/master/doorLock",
                        isAdmin: true,
                    },
                    {
                        id: "torsionTube",
                        title: "Torsion Tube",
                        type: "item",
                        url: "/apps/master/torsionTube",
                        isAdmin: true,
                    },
                    {
                        id: "perforatedAngle",
                        title: "Perforated Angle",
                        type: "item",
                        url: "/apps/master/perforatedAngle",
                        isAdmin: true,
                    },
                    {
                        id: "hardwareBox",
                        title: "Hardware Box",
                        type: "item",
                        url: "/apps/master/hardwareBox",
                        isAdmin: true,
                    },
                ].filter((item) => {
                    const getRole = localStorage.getItem("user");
                    const { roleName } = getRole ? JSON.parse(getRole) : "";
                    const isAdmin =
                        roleName === "SUPERADMIN" ? true : !item.isAdmin;
                    return item.isAdmin !== undefined ? isAdmin : true;
                }),
            },
            {
                id: "decorativeItems",
                title: "Decorative Items",
                translate: "Decorative Items",
                type: "collapsable",
                icon: "dashboard",
                isAdmin: true,
                children: [
                    {
                        id: "decorativeCategory",
                        title: "Decorative Category",
                        type: "item",
                        url: "/apps/decorative/category-list",
                        isAdmin: true,
                    },
                    {
                        id: "decorativeItem",
                        title: "Decorative Item",
                        type: "item",
                        url: "/apps/decorative/item-list",
                        isAdmin: true,
                    },
                    {
                        id: "decorativePanel",
                        title: "Decorative Panel",
                        type: "item",
                        url: "/apps/decorative/panel-list",
                        isAdmin: true,
                    },
                ].filter((item) => {
                    const getRole = localStorage.getItem("user");
                    const { roleName } = getRole ? JSON.parse(getRole) : "";
                    const isAdmin =
                        roleName === "SUPERADMIN" ? true : !item.isAdmin;
                    return item.isAdmin !== undefined ? isAdmin : true;
                })
            },
            {
                id: "partsMaster",
                title: "Parts Master",
                translate: "Parts Master",
                type: "collapsable",
                icon: "dashboard",
                isAdmin: true,
                children: [
                    {
                        id: "partsCategory",
                        title: "Parts Category Master",
                        type: "item",
                        url: "/apps/parts-master/parts-category-master",
                        isAdmin: true,
                    },
                    {
                        id: "partsSubCategory",
                        title: "Parts Sub Category Master",
                        type: "item",
                        url: "/apps/parts-master/parts-sub-category-master",
                        isAdmin: true,
                    }
                ].filter((item) => {
                    const getRole = localStorage.getItem("user");
                    const { roleName } = getRole ? JSON.parse(getRole) : "";
                    const isAdmin =
                        roleName === "SUPERADMIN" ? true : !item.isAdmin;
                    return item.isAdmin !== undefined ? isAdmin : true;
                })
            },
            {
                id: "master",
                title: "Price Configuration",
                translate: "Price Configuration",
                type: "collapsable",
                icon: "folder",
                isAdmin: true,
                children: [
                    {
                        id: "multiplier",
                        title: "Quotation Multiplier",
                        type: "item",
                        url: "/apps/master/multiplier",
                    },
                    {
                        id: "doorModalPrice",
                        title: "Door Price Configuration",
                        type: "item",
                        url: "/apps/master/doorModalPrice",
                        isAdmin: true,
                    },
                    {
                        id: "strutPriceConfiguration",
                        title: "Strut Price Configuration",
                        type: "item",
                        url: "/apps/master/strutPriceConfiguration",
                        isAdmin: true,
                    },
                    {
                        id: "customerLevel",
                        title: "Customer Level",
                        type: "item",
                        url: "/apps/master/customerLevel",
                    },
                    {
                        id: "stateTax",
                        title: "State tax",
                        type: "item",
                        url: "/apps/master/stateTax",
                        isAdmin: true,
                    },
                    {
                        id: "springCategoryConfiguration",
                        title: "Spring Category Configuration",
                        type: "item",
                        url: "/apps/master/springCategoryConfiguration",
                    },
                ].filter((item) => {
                    const getRole = localStorage.getItem("user");
                    const { roleName } = getRole ? JSON.parse(getRole) : "";
                    const isAdmin =
                        roleName === "SUPERADMIN" ? true : !item.isAdmin;
                    return item.isAdmin !== undefined ? isAdmin : true;
                }),
            },
            {
                id: "master",
                title: "Remarks",
                translate: "Remarks",
                type: "collapsable",
                icon: "folder",
                isAdmin: true,
                children: [
                    {
                        id: "doorRemark",
                        title: "Door Remark",
                        type: "item",
                        url: "/apps/master/doorRemark",
                    },
                    {
                        id: "remarks",
                        title: "Remarks Master",
                        type: "item",
                        url: "/apps/master/remarks",
                        isAdmin: true,
                    },
                    {
                        id: "commonRemarks",
                        title: "Common Remarks",
                        type: "item",
                        url: "/apps/master/commonRemarks",
                        isAdmin: true,
                    },
                    {
                        id: "terms",
                        title: "Terms",
                        type: "item",
                        url: "/apps/master/terms",
                        isAdmin: true,
                    },
                ].filter((item) => {
                    const getRole = localStorage.getItem("user");
                    const { roleName } = getRole ? JSON.parse(getRole) : "";
                    const isAdmin =
                        roleName === "SUPERADMIN" ? true : !item.isAdmin;
                    return item.isAdmin !== undefined ? isAdmin : true;
                }),
            },
            {
                id: "emailTemplate",
                title: "Email Template",
                type: "item",
                icon: "help",
                url: "/apps/email-template/list",
                isAdmin: true,
            },
            // {
            //     id: "priceConfiguration",
            //     title: "Price Configuration",
            //     translate: "Price Configuration",
            //     type: "collapsable",
            //     icon: "attach_money",
            //     isAdmin: true,
            //     children: [
            //         {
            //             id: "colorCombo",
            //             title: "Color Combo",
            //             type: "item",
            //             url: "/apps/priceConfiguration/colorCombo",
            //         },
            //         {
            //             id: "modelColor",
            //             title: "Model Color",
            //             type: "item",
            //             url: "/apps/priceConfiguration/project",
            //         },
            //         {
            //             id: "modelPrice",
            //             title: "Model Price",
            //             type: "item",
            //             url: "/apps/priceConfiguration/project",
            //         },

            //         {
            //             id: "modelCColorVisulization",
            //             title: "Model Color Visulization",
            //             type: "item",
            //             url: "/apps/priceConfiguration/project",
            //         },
            //     ],
            // },
            // {
            //     id: "userManagementRole",
            //     title: "User Management",
            //     translate: "User Management",
            //     type: "collapsable",
            //     icon: "folder",
            //     children: [
            //         {
            //             id: "functionality",
            //             title: "Functionality",
            //             type: "item",
            //             url: "/apps/userManagementRole/project",
            //         },
            //         {
            //             id: "roleFunctionality",
            //             title: "Role Functionality",
            //             type: "item",
            //             url: "/apps/userManagementRole/project",
            //         },
            //         {
            //             id: "userList",
            //             title: "User List",
            //             type: "item",
            //             url: "/apps/userManagementRole/project",
            //         },
            //         {
            //             id: "roleMaster",
            //             title: "Role master",
            //             type: "item",
            //             url: "/apps/roles-permission/list",
            //             isAdmin: true,
            //         },
            //     ],
            // },
            {
                id: "profile",
                title: "Profile",
                type: "item",
                icon: "person",
                url: "/pages/profile",
            },
            
            {
                id: "master",
                title: "User Management",
                translate: "User Management",
                type: "collapsable",
                icon: "folder",
                isAdmin: true,
                children: [
                    {
                        id: "roleMaster",
                        title: "Role master",
                        type: "item",
                        url: "/apps/master/roleMaster",
                        isAdmin: true,
                    },
                ].filter((item) => {
                    const getRole = localStorage.getItem("user");
                    const { roleName } = getRole ? JSON.parse(getRole) : "";
                    const isAdmin =
                        roleName === "SUPERADMIN" ? true : !item.isAdmin;
                    return item.isAdmin !== undefined ? isAdmin : true;
                }),
            },

            // {
            //     id: 'rolesAndPermissions',
            //     title: 'Roles And Permissions',
            //     type: 'item',
            //     icon: 'person',
            //     url: '/apps/roles-permission/list'
            // },
            // {
            //     id: 'authentication',
            //     title: 'Authentication',
            //     type: 'collapsable',
            //     icon: 'lock',
            //     badge: {
            //         title: '10',
            //         bg: '#525e8a',
            //         fg: '#FFFFFF'
            //     },
            //     children: [

            //         {
            //             id: 'login-v2',
            //             title: 'Login v2',
            //             type: 'item',
            //             url: '/pages/auth/login-2'
            //         },
            //         {
            //             id: 'register-v2',
            //             title: 'Register v2',
            //             type: 'item',
            //             url: '/pages/auth/register-2'
            //         },
            //         {
            //             id: 'forgot-password-v2',
            //             title: 'Forgot Password v2',
            //             type: 'item',
            //             url: '/pages/auth/forgot-password-2'
            //         },
            //         {
            //             id: 'reset-password-v2',
            //             title: 'Reset Password v2',
            //             type: 'item',
            //             url: '/pages/auth/reset-password-2'
            //         },
            //         {
            //             id: 'lock-screen',
            //             title: 'Lock Screen',
            //             type: 'item',
            //             url: '/pages/auth/lock'
            //         },
            //         {
            //             id: 'mail-confirmation',
            //             title: 'Mail Confirmation',
            //             type: 'item',
            //             url: '/pages/auth/mail-confirm'
            //         }
            //     ]
            // },
            {
                id: "pages",
                title: "Pages",
                type: "group",
                icon: "pages",
                children: [
                    // {
                    //     id: 'coming-soon',
                    //     title: 'Coming Soon',
                    //     type: 'item',
                    //     icon: 'alarm',
                    //     url: '/pages/coming-soon'
                    // },
                    // {
                    //     id      : 'errors',
                    //     title   : 'Errors',
                    //     type    : 'collapsable',
                    //     icon    : 'error',
                    //     children: [
                    //         {
                    //             id   : '404',
                    //             title: '404',
                    //             type : 'item',
                    //             url  : '/pages/errors/error-404'
                    //         },
                    //         {
                    //             id   : '500',
                    //             title: '500',
                    //             type : 'item',
                    //             url  : '/pages/errors/error-500'
                    //         }
                    //     ]
                    // },
                    // {
                    //     id      : 'invoice',
                    //     title   : 'Invoice',
                    //     type    : 'collapsable',
                    //     icon    : 'receipt',
                    //     children: [
                    //         {
                    //             id   : 'modern',
                    //             title: 'Modern',
                    //             type : 'item',
                    //             url  : '/pages/invoices/modern'
                    //         },
                    //         {
                    //             id   : 'compact',
                    //             title: 'Compact',
                    //             type : 'item',
                    //             url  : '/pages/invoices/compact'
                    //         }
                    //     ]
                    // },
                    // {
                    //     id: 'maintenance',
                    //     title: 'Maintenance',
                    //     type: 'item',
                    //     icon: 'build',
                    //     url: '/pages/maintenance'
                    // },
                    // {
                    //     id      : 'pricing',
                    //     title   : 'Pricing',
                    //     type    : 'collapsable',
                    //     icon    : 'attach_money',
                    //     children: [
                    //         {
                    //             id   : 'style-1',
                    //             title: 'Style 1',
                    //             type : 'item',
                    //             url  : '/pages/pricing/style-1'
                    //         },
                    //         {
                    //             id   : 'style-2',
                    //             title: 'Style 2',
                    //             type : 'item',
                    //             url  : '/pages/pricing/style-2'
                    //         },
                    //         {
                    //             id   : 'style-3',
                    //             title: 'Style 3',
                    //             type : 'item',
                    //             url  : '/pages/pricing/style-3'
                    //         }
                    //     ]
                    // },

                    // {
                    //     id: 'search',
                    //     title: 'Search',
                    //     type: 'collapsable',
                    //     icon: 'search',
                    //     children: [
                    //         {
                    //             id: 'search-classic',
                    //             title: 'Classic',
                    //             type: 'item',
                    //             url: '/pages/search/classic'
                    //         },
                    //         {
                    //             id: 'search-modern',
                    //             title: 'Modern',
                    //             type: 'item',
                    //             url: '/pages/search/modern'
                    //         }
                    //     ]
                    // },
                    {
                        id: "faq",
                        title: "Faq",
                        type: "item",
                        icon: "help",
                        url: "/pages/faq",
                    },
                    // {
                    //     id: "knowledge-base",
                    //     title: "Knowledge Base",
                    //     type: "item",
                    //     icon: "import_contacts",
                    //     url: "/pages/knowledge-base",
                    // },
                ],
            },
            {
                id: "calendar",
                title: "Calendar",
                translate: "NAV.CALENDAR",
                type: "item",
                icon: "today",
                url: "/apps/calendar",
            },
            // {
            //     id       : 'e-commerce',
            //     title    : 'E-Commerce',
            //     translate: 'NAV.ECOMMERCE',
            //     type     : 'collapsable',
            //     icon     : 'shopping_cart',
            //     children : [
            //         {
            //             id        : 'products',
            //             title     : 'Products',
            //             type      : 'item',
            //             url       : '/apps/e-commerce/products',
            //             exactMatch: true
            //         },
            //         {
            //             id        : 'productDetail',
            //             title     : 'Product Detail',
            //             type      : 'item',
            //             url       : '/apps/e-commerce/products/1/printed-dress',
            //             // exactMatch: true
            //         },
            //         {
            //             id        : 'orders',
            //             title     : 'Orders',
            //             type      : 'item',
            //             url       : '/apps/e-commerce/orders',
            //             exactMatch: true
            //         },
            //         {
            //             id        : 'orderDetail',
            //             title     : 'Order Detail',
            //             type      : 'item',
            //             url       : '/apps/e-commerce/orders/1',
            //             exactMatch: true
            //         }
            //     ]
            // },
            {
                id: "academy",
                title: "Academy",
                translate: "NAV.ACADEMY",
                type: "item",
                icon: "school",
                url: "/apps/academy",
            },
            // {
            //     id: 'mail',
            //     title: 'Mail',
            //     translate: 'NAV.MAIL.TITLE',
            //     type: 'item',
            //     icon: 'email',
            //     url: '/apps/mail',
            //     badge: {
            //         title: '25',
            //         translate: 'NAV.MAIL.BADGE',
            //         bg: '#F44336',
            //         fg: '#FFFFFF'
            //     }
            // },
            // {
            //     id       : 'mail-ngrx',
            //     title    : 'Mail Ngrx',
            //     translate: 'NAV.MAIL_NGRX.TITLE',
            //     type     : 'item',
            //     icon     : 'email',
            //     url      : '/apps/mail-ngrx',
            //     badge    : {
            //         title    : '13',
            //         translate: 'NAV.MAIL_NGRX.BADGE',
            //         bg       : '#EC0C8E',
            //         fg       : '#FFFFFF'
            //     }
            // },
            // {
            //     id       : 'chat',
            //     title    : 'Chat',
            //     translate: 'NAV.CHAT',
            //     type     : 'item',
            //     icon     : 'chat',
            //     url      : '/apps/chat',
            //     badge    : {
            //         title: '13',
            //         bg   : '#09d261',
            //         fg   : '#FFFFFF'
            //     }
            // },
            // {
            //     id: 'file-manager',
            //     title: 'File Manager',
            //     translate: 'NAV.FILE_MANAGER',
            //     type: 'item',
            //     icon: 'folder',
            //     url: '/apps/file-manager'
            // },
            // {
            //     id: 'contacts',
            //     title: 'Contacts',
            //     translate: 'NAV.CONTACTS',
            //     type: 'item',
            //     icon: 'account_box',
            //     url: '/apps/contacts'
            // },
            // {
            //     id: 'to-do',
            //     title: 'To-Do',
            //     translate: 'NAV.TODO',
            //     type: 'item',
            //     icon: 'check_box',
            //     url: '/apps/todo',
            //     badge: {
            //         title: '3',
            //         bg: '#FF6F00',
            //         fg: '#FFFFFF'
            //     }
            // },
            // {
            //     id       : 'scrumboard',
            //     title    : 'Scrumboard',
            //     translate: 'NAV.SCRUMBOARD',
            //     type     : 'item',
            //     icon     : 'assessment',
            //     url      : '/apps/scrumboard'
            // }
        ].filter((item) => {
            const getRole = localStorage.getItem("user");
            const { roleName } = getRole ? JSON.parse(getRole) : "";
            const isAdmin = roleName === "SUPERADMIN" ? true : !item.isAdmin;
            return item.isAdmin !== undefined ? isAdmin : true;
        }),
    },

    // {
    //     id: 'user-interface',
    //     title: 'User Interface',
    //     type: 'group',
    //     icon: 'web',
    //     children: [
    //         {
    //             id: 'angular-material',
    //             title: 'Angular Material',
    //             type: 'item',
    //             icon: 'ballot',
    //             url: '/ui/angular-material'
    //         },
    //         {
    //             id: 'cards',
    //             title: 'Cards',
    //             type: 'item',
    //             icon: 'crop_portrait',
    //             url: '/ui/cards'
    //         },
    //         {
    //             id: 'forms',
    //             title: 'Forms',
    //             type: 'item',
    //             icon: 'web_asset',
    //             url: '/ui/forms'
    //         },
    //         {
    //             id: 'icons',
    //             title: 'Icons',
    //             type: 'item',
    //             icon: 'photo',
    //             url: '/ui/icons'
    //         },
    //         {
    //             id: 'typography',
    //             title: 'Typography',
    //             type: 'item',
    //             icon: 'text_fields',
    //             url: '/ui/typography'
    //         },
    //         {
    //             id: 'helper-classes',
    //             title: 'Helper Classes',
    //             type: 'item',
    //             icon: 'help',
    //             url: '/ui/helper-classes'
    //         },
    //         {
    //             id: 'page-layouts',
    //             title: 'Page Layouts',
    //             type: 'collapsable',
    //             icon: 'view_quilt',
    //             children: [
    //                 {
    //                     id: 'carded',
    //                     title: 'Carded',
    //                     type: 'collapsable',
    //                     badge: {
    //                         title: '12',
    //                         bg: '#525e8a',
    //                         fg: '#FFFFFF'
    //                     },
    //                     children: [
    //                         {
    //                             id: 'full-width-1',
    //                             title: 'Full Width #1',
    //                             type: 'item',
    //                             url: '/ui/page-layouts/carded/full-width-1'
    //                         },
    //                         {
    //                             id: 'full-width-2',
    //                             title: 'Full Width #2',
    //                             type: 'item',
    //                             url: '/ui/page-layouts/carded/full-width-2'
    //                         },
    //                         {
    //                             id: 'full-width-tabbed-1',
    //                             title: 'Full Width Tabbed #1',
    //                             type: 'item',
    //                             url: '/ui/page-layouts/carded/full-width-tabbed-1'
    //                         },
    //                         {
    //                             id: 'full-width-tabbed-2',
    //                             title: 'Full Width Tabbed #2',
    //                             type: 'item',
    //                             url: '/ui/page-layouts/carded/full-width-tabbed-2'
    //                         },
    //                         {
    //                             id: 'left-sidebar-1',
    //                             title: 'Left Sidebar #1',
    //                             type: 'item',
    //                             url: '/ui/page-layouts/carded/left-sidebar-1'
    //                         },
    //                         {
    //                             id: 'left-sidebar-2',
    //                             title: 'Left Sidebar #2',
    //                             type: 'item',
    //                             url: '/ui/page-layouts/carded/left-sidebar-2'
    //                         },
    //                         {
    //                             id: 'left-sidebar-tabbed-1',
    //                             title: 'Left Sidebar Tabbed #1',
    //                             type: 'item',
    //                             url: '/ui/page-layouts/carded/left-sidebar-tabbed-1'
    //                         },
    //                         {
    //                             id: 'left-sidebar-tabbed-2',
    //                             title: 'Left Sidebar Tabbed #2',
    //                             type: 'item',
    //                             url: '/ui/page-layouts/carded/left-sidebar-tabbed-2'
    //                         },
    //                         {
    //                             id: 'right-sidebar-1',
    //                             title: 'Right Sidebar #1',
    //                             type: 'item',
    //                             url: '/ui/page-layouts/carded/right-sidebar-1'
    //                         },
    //                         {
    //                             id: 'right-sidebar-2',
    //                             title: 'Right Sidebar #2',
    //                             type: 'item',
    //                             url: '/ui/page-layouts/carded/right-sidebar-2'
    //                         },
    //                         {
    //                             id: 'right-sidebar-tabbed-1',
    //                             title: 'Right Sidebar Tabbed #1',
    //                             type: 'item',
    //                             url: '/ui/page-layouts/carded/right-sidebar-tabbed-1'
    //                         },
    //                         {
    //                             id: 'right-sidebar-tabbed-2',
    //                             title: 'Right Sidebar Tabbed #2',
    //                             type: 'item',
    //                             url: '/ui/page-layouts/carded/right-sidebar-tabbed-2'
    //                         }
    //                     ]
    //                 },
    //                 {
    //                     id: 'simple',
    //                     title: 'Simple',
    //                     type: 'collapsable',
    //                     badge: {
    //                         title: '10',
    //                         bg: '#525e8a',
    //                         fg: '#FFFFFF'
    //                     },
    //                     children: [
    //                         {
    //                             id: 'full-width-1',
    //                             title: 'Full Width #1',
    //                             type: 'item',
    //                             url: '/ui/page-layouts/simple/full-width-1'
    //                         },
    //                         {
    //                             id: 'full-width-tabbed-1',
    //                             title: 'Full Width Tabbed #1',
    //                             type: 'item',
    //                             url: '/ui/page-layouts/simple/full-width-tabbed-1'
    //                         },
    //                         {
    //                             id: 'left-sidebar-1',
    //                             title: 'Left Sidebar #1',
    //                             type: 'item',
    //                             url: '/ui/page-layouts/simple/left-sidebar-1'
    //                         },
    //                         {
    //                             id: 'left-sidebar-2',
    //                             title: 'Left Sidebar #2',
    //                             type: 'item',
    //                             url: '/ui/page-layouts/simple/left-sidebar-2'
    //                         },
    //                         {
    //                             id: 'left-sidebar-3',
    //                             title: 'Left Sidebar #3',
    //                             type: 'item',
    //                             url: '/ui/page-layouts/simple/left-sidebar-3'
    //                         },
    //                         {
    //                             id: 'left-sidebar-4',
    //                             title: 'Left Sidebar #4',
    //                             type: 'item',
    //                             url: '/ui/page-layouts/simple/left-sidebar-4'
    //                         },
    //                         {
    //                             id: 'right-sidebar-1',
    //                             title: 'Right Sidebar #1',
    //                             type: 'item',
    //                             url: '/ui/page-layouts/simple/right-sidebar-1'
    //                         },
    //                         {
    //                             id: 'right-sidebar-2',
    //                             title: 'Right Sidebar #2',
    //                             type: 'item',
    //                             url: '/ui/page-layouts/simple/right-sidebar-2'
    //                         },
    //                         {
    //                             id: 'right-sidebar-3',
    //                             title: 'Right Sidebar #3',
    //                             type: 'item',
    //                             url: '/ui/page-layouts/simple/right-sidebar-3'
    //                         },
    //                         {
    //                             id: 'right-sidebar-4',
    //                             title: 'Right Sidebar #4',
    //                             type: 'item',
    //                             url: '/ui/page-layouts/simple/right-sidebar-4'
    //                         }
    //                     ]
    //                 },
    //                 {
    //                     id: 'blank',
    //                     title: 'Blank',
    //                     type: 'item',
    //                     url: '/ui/page-layouts/blank'
    //                 }
    //             ]
    //         },
    //         {
    //             id: 'colors',
    //             title: 'Colors',
    //             type: 'item',
    //             icon: 'color_lens',
    //             url: '/ui/colors'
    //         }
    //     ]
    // },
    // {
    //     id: 'documentation',
    //     title: 'Documentation',
    //     icon: 'import_contacts',
    //     type: 'group',
    //     children: [
    //         {
    //             id: 'changelog',
    //             title: 'Changelog',
    //             type: 'item',
    //             icon: 'update',
    //             url: '/documentation/changelog',
    //             badge: {
    //                 title: '10.0.0',
    //                 bg: '#EC0C8E',
    //                 fg: '#FFFFFF'
    //             }
    //         },
    //         {
    //             id: 'google-maps',
    //             title: 'Google Maps',
    //             type: 'item',
    //             icon: 'map',
    //             url: '/documentation/components-third-party/google-maps'
    //         },
    //         // {
    //         //     id      : 'getting-started',
    //         //     title   : 'Getting Started',
    //         //     type    : 'collapsable',
    //         //     icon    : 'import_contacts',
    //         //     children: [
    //         //         {
    //         //             id   : 'introduction',
    //         //             title: 'Introduction',
    //         //             type : 'item',
    //         //             url  : '/documentation/getting-started/introduction'
    //         //         },
    //         //         {
    //         //             id   : 'installation',
    //         //             title: 'Installation',
    //         //             type : 'item',
    //         //             url  : '/documentation/getting-started/installation'
    //         //         }
    //         //     ]
    //         // },
    //         // {
    //         //     id      : 'working-with-fuse',
    //         //     title   : 'Working with Fuse',
    //         //     type    : 'collapsable',
    //         //     icon    : 'import_contacts',
    //         //     children: [
    //         //         {
    //         //             id   : 'server',
    //         //             title: 'Server',
    //         //             type : 'item',
    //         //             url  : '/documentation/working-with-fuse/server'
    //         //         },
    //         //         {
    //         //             id   : 'production',
    //         //             title: 'Production',
    //         //             type : 'item',
    //         //             url  : '/documentation/working-with-fuse/production'
    //         //         },
    //         //         {
    //         //             id   : 'directory-structure',
    //         //             title: 'Directory Structure',
    //         //             type : 'item',
    //         //             url  : '/documentation/working-with-fuse/directory-structure'
    //         //         },
    //         //         {
    //         //             id   : 'updating-fuse',
    //         //             title: 'Updating Fuse',
    //         //             type : 'item',
    //         //             url  : '/documentation/working-with-fuse/updating-fuse'
    //         //         },
    //         //         {
    //         //             id   : 'multi-language',
    //         //             title: 'Multi Language',
    //         //             type : 'item',
    //         //             url  : '/documentation/working-with-fuse/multi-language'
    //         //         },
    //         //         {
    //         //             id   : 'material-theming',
    //         //             title: 'Material Theming',
    //         //             type : 'item',
    //         //             url  : '/documentation/working-with-fuse/material-theming'
    //         //         },
    //         //         {
    //         //             id   : 'theme-layouts',
    //         //             title: 'Theme Layouts',
    //         //             type : 'item',
    //         //             url  : '/documentation/working-with-fuse/theme-layouts'
    //         //         },
    //         //         {
    //         //             id   : 'page-layouts',
    //         //             title: 'Page Layouts',
    //         //             type : 'item',
    //         //             url  : '/documentation/working-with-fuse/page-layouts'
    //         //         }
    //         //     ]
    //         // },
    //         // {
    //         //     id      : 'components',
    //         //     title   : 'Components',
    //         //     type    : 'collapsable',
    //         //     icon    : 'import_contacts',
    //         //     children: [
    //         //         {
    //         //             id   : 'countdown',
    //         //             title: 'Countdown',
    //         //             type : 'item',
    //         //             url  : '/documentation/components/countdown'
    //         //         },
    //         //         {
    //         //             id   : 'highlight',
    //         //             title: 'Highlight',
    //         //             type : 'item',
    //         //             url  : '/documentation/components/highlight'
    //         //         },
    //         //         {
    //         //             id   : 'material-color-picker',
    //         //             title: 'Material Color Picker',
    //         //             type : 'item',
    //         //             url  : '/documentation/components/material-color-picker'
    //         //         },
    //         //         {
    //         //             id   : 'navigation',
    //         //             title: 'Navigation',
    //         //             type : 'item',
    //         //             url  : '/documentation/components/navigation'
    //         //         },
    //         //         {
    //         //             id   : 'progress-bar',
    //         //             title: 'Progress Bar',
    //         //             type : 'item',
    //         //             url  : '/documentation/components/progress-bar'
    //         //         },
    //         //         {
    //         //             id   : 'search-bar',
    //         //             title: 'Search Bar',
    //         //             type : 'item',
    //         //             url  : '/documentation/components/search-bar'
    //         //         },
    //         //         {
    //         //             id   : 'sidebar',
    //         //             title: 'Sidebar',
    //         //             type : 'item',
    //         //             url  : '/documentation/components/sidebar'
    //         //         },
    //         //         {
    //         //             id   : 'shortcuts',
    //         //             title: 'Shortcuts',
    //         //             type : 'item',
    //         //             url  : '/documentation/components/shortcuts'
    //         //         },
    //         //         {
    //         //             id   : 'widget',
    //         //             title: 'Widget',
    //         //             type : 'item',
    //         //             url  : '/documentation/components/widget'
    //         //         }
    //         //     ]
    //         // },
    //         // {
    //         //     id      : '3rd-party-components',
    //         //     title   : '3rd Party Components',
    //         //     type    : 'collapsable',
    //         //     icon    : 'import_contacts',
    //         //     children: [
    //         //         {
    //         //             id      : 'datatables',
    //         //             title   : 'Datatables',
    //         //             type    : 'collapsable',
    //         //             children: [
    //         //                 {
    //         //                     id   : 'ngxdatatable',
    //         //                     title: 'ngx-datatable',
    //         //                     type : 'item',
    //         //                     url  : '/documentation/components-third-party/datatables/ngx-datatable'
    //         //                 }
    //         //             ]
    //         //         },
    //         //         {
    //         //             id   : 'google-maps',
    //         //             title: 'Google Maps',
    //         //             type : 'item',
    //         //             url  : '/documentation/components-third-party/google-maps'
    //         //         }
    //         //     ]
    //         // },
    //         // {
    //         //     id      : 'directives',
    //         //     title   : 'Directives',
    //         //     type    : 'collapsable',
    //         //     icon    : 'import_contacts',
    //         //     children: [
    //         //         {
    //         //             id   : 'fuse-if-on-dom',
    //         //             title: 'fuseIfOnDom',
    //         //             type : 'item',
    //         //             url  : '/documentation/directives/fuse-if-on-dom'
    //         //         },
    //         //         {
    //         //             id   : 'fuse-inner-scroll',
    //         //             title: 'fuseInnerScroll',
    //         //             type : 'item',
    //         //             url  : '/documentation/directives/fuse-inner-scroll'
    //         //         },
    //         //         {
    //         //             id   : 'fuse-mat-sidenav',
    //         //             title: 'fuseMatSidenav',
    //         //             type : 'item',
    //         //             url  : '/documentation/directives/fuse-mat-sidenav'
    //         //         },
    //         //         {
    //         //             id   : 'fuse-perfect-scrollbar',
    //         //             title: 'fusePerfectScrollbar',
    //         //             type : 'item',
    //         //             url  : '/documentation/directives/fuse-perfect-scrollbar'
    //         //         }
    //         //     ]
    //         // },
    //         // {
    //         //     id      : 'services',
    //         //     title   : 'Services',
    //         //     type    : 'collapsable',
    //         //     icon    : 'import_contacts',
    //         //     children: [
    //         //         {
    //         //             id   : 'fuse-config',
    //         //             title: 'Fuse Config',
    //         //             type : 'item',
    //         //             url  : '/documentation/services/fuse-config'
    //         //         },
    //         //         {
    //         //             id   : 'fuse-splash-screen',
    //         //             title: 'Fuse Splash Screen',
    //         //             type : 'item',
    //         //             url  : '/documentation/services/fuse-splash-screen'
    //         //         }
    //         //     ]
    //         // }
    //     ]
    // }
];
