import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToasterService } from 'app/main/components/toaster.service';
import { catchError } from 'rxjs/operators';
import { NgxSpinnerService } from "ngx-spinner";
import { LoaderService } from 'app/services/loader.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(
        private router: Router,
        private toaster: ToasterService,
        private loadingService: LoaderService
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        // Get the auth token from the service.
        var authToken = '';
        var userPayLoad = JSON.parse(localStorage.getItem("user") ?? "{}");
        if (userPayLoad != null && userPayLoad != undefined && userPayLoad.access_token != null && userPayLoad.access_token != undefined) {
            authToken = userPayLoad.access_token;
        }

        // Clone the request and replace the original headers with
        // cloned headers, updated with the authorization.
        const authReq = req.clone({
            headers: req.headers.set('Authorization', 'Bearer ' + authToken)
        });
        
        // send cloned request with header to the next handler.
        const data = next.handle(authReq).pipe(
            catchError((error: any, caught) => {
                console.log(error);
                if (error && error.status == 401) {
                    this.router.navigate(['/pages/auth/login'])
                }
                if (error && error.error && error.error.errors) {
                    console.log(error.error.errors);
                    let errorStr = error.error.title || 'Something went wrong!';
                    this.toaster.show('error', 'Error', errorStr);
                }
                if (error && error && error.errors) {
                    let errorStr = error.errors[0] || 'Something went wrong!';
                    this.toaster.show('error', 'Error', errorStr);
                }
                throw error;
            })
        );
        return data;
    }
}